import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonTopVoice = () => {
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <SkeletonTheme
          baseColor="rgba(26, 31, 38, 0.8)"
          highlightColor="rgba(168, 179, 207, 0.5)"
        >
          <div
            key={index}
            className="column-alignment post-card-wrapper"
            style={{
              display: "flex",
              gap: 10,
              marginBottom: 20,
            }}
          >
            <Skeleton
              height={40}
              width={40}
              style={{
                borderRadius: "12px",
              }}
              className="skeleton-loader"
            />
            <div style={{ flex: 1 }}>
              <Skeleton
                height={20}
                width={`60%`}
                style={{
                  marginBottom: 10,
                }}
                className="skeleton-loader"
              />
              <Skeleton
                height={160}
                style={{
                  borderRadius: "12px",
                }}
                className="skeleton-loader"
              />
              <Skeleton
                height={20}
                width={`30%`}
                style={{
                  marginTop: 10,
                }}
                className="skeleton-loader"
              />
            </div>
          </div>
        </SkeletonTheme>
      ))}
    </>
  );
};

export default SkeletonTopVoice;
