import { Page, Block, Toolbar, Link, Tabs, Tab } from "framework7-react";
import NavBar from "../../components/navbar";
import { useState, useEffect } from "react";
import SkeletonPosts from "../../components/skeleton/skeleton-posts/skeleton-posts";
import PostCard from "../../components/post-card/post-card";
import SkeletonJobs from "../../components/skeleton/skeleton-jobs/skeleton-jobs";
import SkeletonTopVoice from "../../components/skeleton/skeleton-top-voice/skeleton-top-voice";
import AuthorProfile from "../../components/author-card/author-card";

const UserProfilePage = () => {
  const [username, setUsername] = useState("");
  const [bookmarkedPosts, setBookmarkedPosts] = useState([]);
  const [bookmarkedJobs, setBookmarkedJobs] = useState([]); // Only 'job' category
  const [bookmarkedAuthors, setBookmarkedAuthors] = useState([]); // Filter by available authors
  const [loadingPosts, setLoadingPosts] = useState(true);

  // Retrieve username from localStorage when the component mounts
  useEffect(() => {
    const storedProfile = localStorage.getItem("profile");
    if (storedProfile) {
      // Parse the JSON string into an object
      const profileObject = JSON.parse(storedProfile);
      console.log(profileObject.username); // Check if username exists in the parsed object
      if (profileObject.username) {
        setUsername(profileObject.username);
      }
    } else {
      console.log("Profile not found in localStorage");
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const fetchData = async (setLoading) => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/get-bookmarks`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const bookmarkResponse = await response.json();
          const bookmarkedPosts = bookmarkResponse.data.filter(
            (bookmark) => bookmark.posts?.category === "post"
          );
          setBookmarkedPosts(bookmarkedPosts);

          const bookmarkedJobs = bookmarkResponse.data.filter(
            (bookmark) => bookmark.posts?.category === "job"
          );
          //   console.log("posts", posts);
          setBookmarkedJobs(bookmarkedJobs);

          const bookmarkedAuthor = bookmarkResponse.data.filter(
            (bookmark) => bookmark.post_id === null
          );
          //   console.log("posts", posts);
          setBookmarkedAuthors(bookmarkedAuthor);
        } else {
          console.error(`Failed to fetch bookmarks posts`);
        }
      } catch (error) {
        console.error(`Error fetching bookmarks posts`, error);
      } finally {
        setLoading(false);
      }
    };
    fetchData(setLoadingPosts);
  }, []);
  // Empty array ensures this effect runs once when the component mounts
  return (
    <Page>
      <NavBar
        showBackButton={true}
        showUserName={true}
        username={username}
        showJoinCommunity={false}
      />
      <Toolbar top tabbar style={{ marginTop: "60px" }}>
        <Link
          style={{ fontSize: 12 }}
          tabLink="#bookmarked-posts"
          tabLinkActive
        >
          Bookmarked Posts
        </Link>
        <Link style={{ fontSize: 12 }} tabLink="#bookmarked-jobs">
          Bookmarked Jobs
        </Link>
        <Link style={{ fontSize: 12 }} tabLink="#bookmarked-authors">
          Bookmarked Authors
        </Link>
      </Toolbar>

      <Tabs swipeable>
        <Tab id="bookmarked-posts" className="page-content" tabActive>
          <Block style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="column-alignment">
              <div className="column-alignment grid grid-cols-1">
                {loadingPosts ? (
                  <SkeletonPosts />
                ) : (
                  bookmarkedPosts.map((post, index) => (
                    <PostCard
                      key={index}
                      post={post.posts}
                      showBookMark={false}
                    />
                  ))
                )}
              </div>
            </div>
          </Block>
        </Tab>
        <Tab id="bookmarked-jobs" className="page-content">
          <Block style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="column-alignment">
              <div className="column-alignment grid grid-cols-1">
                {loadingPosts ? (
                  <SkeletonJobs />
                ) : (
                  bookmarkedJobs.map((post, index) => (
                    <PostCard
                      key={index}
                      post={post.posts}
                      showBookMark={false}
                    />
                  ))
                )}
              </div>
            </div>
          </Block>
        </Tab>
        <Tab id="bookmarked-authors" className="page-content">
          <Block style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="column-alignment">
              <div className="column-alignment grid grid-cols-1">
                {loadingPosts
                  ? SkeletonTopVoice
                  : bookmarkedAuthors.map((author, index) => (
                      <AuthorProfile
                        key={index}
                        author={author.authors}
                        showBookMark={false}
                      />
                    ))}
              </div>
            </div>
          </Block>
        </Tab>
      </Tabs>
    </Page>
  );
};

export default UserProfilePage;
