import { useState, useEffect } from "react";
import {
  Page,
  Block,
  Input,
  Button,
  f7,
  Navbar,
  NavRight,
} from "framework7-react";
import JoinCommunityButton from "../../components/buttons/join-community-button/join-community-button";
import "./resume-review-page.css";
import WebApp from "@twa-dev/sdk";

const ResumeReviewPage = () => {
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [resumeImage, setResumeImage] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserStatus = async () => {
      //   const data = WebApp.initData;
      //   const decodedString = decodeURIComponent(data);
      //   const params = new URLSearchParams(decodedString);
      //   const userJson = params.get("user");
      //   const user = JSON.parse(userJson);
      //   const userId = user.id;
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(
          "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/check-user",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          setIsMember(data.data.isMember);
        } else {
          f7.dialog.alert("Failed to check user status. Please try again.");
        }
      } catch (error) {
        console.error("Error checking user status:", error);
        f7.dialog.alert("Error checking user status. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    checkUserStatus();
  }, []);

  const handleFileUpload = (event) => {
    setResumeImage(event.target.files[0]);
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("authToken");
    if (!title || !details || !resumeImage) {
      f7.dialog.alert("Please fill in all fields and upload a resume.");
      return;
    }

    // Create form data to send to backend
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", details);
    formData.append("resume", resumeImage);

    // Example API request to send data
    fetch("https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/add-resume", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          f7.dialog.alert("Resume submitted successfully!");
        } else {
          f7.dialog.alert("Failed to submit resume. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error submitting resume:", error);
        f7.dialog.alert("Error submitting resume. Please try again.");
      });
  };

  if (isLoading) {
    return (
      <Page>
        <Block>Loading...</Block>
      </Page>
    );
  }

  return (
    <Page style={{ backgroundColor: "#000", minHeight: "100vh" }}>
      <Navbar backLink="Back" backLinkShowText={false}>
        <NavRight>
          <JoinCommunityButton />
        </NavRight>
      </Navbar>
      <Block
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "24px",
            maxWidth: "500px",
            width: "100%",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            position: "relative",
            zIndex: 1,
          }}
        >
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "38px",
              margin: "0 0 24px 0",
              textAlign: "center",
              color: "#000",
            }}
          >
            Submit Your Resume for Review
          </h2>

          {!isMember ? (
            <Block
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                zIndex: 2,
              }}
            >
              <p style={{ fontSize: "18px" }}>
                Please join the community to access this form.
              </p>
            </Block>
          ) : null}

          <form
            className="form-container"
            style={{ pointerEvents: isMember ? "auto" : "none" }}
          >
            <div className="form-group" style={{ marginBottom: "16px" }}>
              <label htmlFor="title" style={{ color: "#000" }}>
                Title
              </label>
              <Input
                type="text"
                placeholder="Enter the title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                style={{
                  borderColor: "#ccc",
                  marginTop: "4px",
                  padding: "10px",
                  color: "#000",
                }}
              />
            </div>
            <div className="form-group" style={{ marginBottom: "16px" }}>
              <label htmlFor="details" style={{ color: "#000" }}>
                Details
              </label>
              <Input
                type="text"
                placeholder="Enter additional details or description"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                required
                style={{
                  borderColor: "#ccc",
                  marginTop: "4px",
                  padding: "10px",
                  color: "#000",
                }}
              />
            </div>
            <div className="form-group" style={{ marginBottom: "24px" }}>
              <label htmlFor="resume" style={{ color: "#000" }}>
                Upload Resume
              </label>
              <Input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                required
                style={{
                  borderColor: "#ccc",
                  marginTop: "4px",
                  padding: "10px",
                  backgroundColor: "#bab5b5",
                }}
              />
            </div>
            <Button
              fill
              onClick={handleSubmit}
              style={{
                backgroundColor: "#1a1f26",
                color: "#a8b3cf",
                borderRadius: "8px",
              }}
            >
              Submit
            </Button>
          </form>
        </div>
      </Block>
    </Page>
  );
};

export default ResumeReviewPage;
