import React from "react";
import { hexToRgba } from "../../utils/colors";

const Tag = ({ text, backgroundColor }) => {
  return (
    <div
      style={{
        backgroundColor: hexToRgba(backgroundColor, 0.2),
        color: backgroundColor,
        borderRadius: "10px",
        padding: "6px 12px",
        fontSize: "12px",
        display: "inline-block",
      }}
    >
      {text}
    </div>
  );
};

export default Tag;
