const hexToRgba = (hex, opacity) => {
  const hexValue = hex.replace("#", "");
  const bigint = parseInt(hexValue, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r},${g},${b},${opacity})`;
};

export { hexToRgba };
