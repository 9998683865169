import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonJobs = () => {
  return (
    <>
      {[...Array(10)].map((_, index) => (
        <SkeletonTheme
          key={index}
          baseColor="rgba(26, 31, 38, 0.8)"
          highlightColor="rgba(168, 179, 207, 0.5)"
        >
          <div
            key={index}
            className="row-alignment post-card-wrapper"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 15,
            }}
          >
            <Skeleton
              height={40}
              width={40}
              style={{
                borderRadius: "12px",
              }}
              className="skeleton-loader"
            />
            <Skeleton height={35} width={150} className="skeleton-loader" />
          </div>
        </SkeletonTheme>
      ))}
    </>
  );
};

export default SkeletonJobs;
