import React from "react";
import { Page, Navbar, NavRight } from "framework7-react";
import JoinCommunityButton from "../../components/buttons/join-community-button/join-community-button";
import "./iframe-page.css";

const IframeScreen = ({ iframeSrc }) => {
  return (
    <Page name="author">
      <Navbar backLink="Back" backLinkShowText={false}>
        <NavRight>
          <JoinCommunityButton />
        </NavRight>
      </Navbar>
      <iframe className="iframe" src={iframeSrc} />
    </Page>
  );
};

export default IframeScreen;
