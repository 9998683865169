import {
  Block,
  Page,
  Navbar,
  NavRight,
  Tabs,
  Tab,
  Link,
  Toolbar,
} from "framework7-react";
import TopmateCard from "../../components/bio-cards/topmate-card";
import { useEffect, useState } from "react";
import PostCard from "../../components/post-card/post-card";
import { FaLinkedin, FaTwitter, FaTelegram } from "react-icons/fa";
import WebApp from "@twa-dev/sdk";
import { postHogHandler } from "../../Posthog/posthog";
import "./author-screen.css";
import renderVerifiedBadge from "../../components/author-badge/author-badge";
import JoinCommunityButton from "../../components/buttons/join-community-button/join-community-button";

const AuthorScreen = ({ uuid }) => {
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        const response = await fetch(
          `https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/get-author?uuid=${uuid}`,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setAuthor(data);
        } else {
          console.error("Failed to fetch author data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAuthor();
  }, [uuid]);

  const SocialLink = ({ href, icon, telegram }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="socialLink"
      onClick={() => {
        const link = href.includes("linkedin")
          ? "linkedin"
          : href.includes("x")
          ? "twitter"
          : "telegram";
        postHogHandler(`${link}-link-click`, {
          name: author?.name,
          uuid: author?.uuid,
        });
        telegram ? WebApp.openTelegramLink(href) : WebApp.openLink(href);
      }}
    >
      {icon}
    </a>
  );

  return (
    <Page>
      <Navbar backLink="Back" backLinkShowText={false}>
        <NavRight>
          <JoinCommunityButton />
        </NavRight>
      </Navbar>
      {author && (
        <Block style={{ paddingRight: 0, paddingLeft: 0 }}>
          <div>
            <div className="authorProfileSection">
              <img
                src={author?.image}
                alt={author?.name}
                className="authorImage"
              />
              <h1 className="authorName">
                {author?.name}{" "}
                {author?.is_verified &&
                  renderVerifiedBadge(author?.level, "25")}
              </h1>
              <div className="authorBio">{author?.bio}</div>

              <div className="socialLinks">
                {author?.linkedin && (
                  <SocialLink
                    href={author?.linkedin}
                    icon={<FaLinkedin size={25} color="white" />}
                  />
                )}
                {author?.twitter && (
                  <SocialLink
                    href={author?.twitter}
                    icon={<FaTwitter size={25} color="white" />}
                  />
                )}
                {author?.telegram && (
                  <SocialLink
                    telegram={true}
                    href={author?.telegram}
                    icon={<FaTelegram size={25} color="white" />}
                  />
                )}
              </div>
            </div>
            <div className="topmateLinks">
              {author?.topmate_links
                ? Object.keys(author?.topmate_links).map((key, index) => (
                    <TopmateCard
                      title={author?.topmate_links[key]["title"]}
                      subtitle={author?.topmate_links[key]["sub-title"]}
                      iframeSrc={author?.topmate_links[key]["link"]}
                    />
                  ))
                : null}
            </div>
            <div className="divider" />
            <Toolbar tabbar>
              <Link style={{ fontSize: 14 }} tabLink="#posts" tabLinkActive>
                Posts
              </Link>
              <Link style={{ fontSize: 14 }} tabLink="#jobs">
                Jobs
              </Link>
            </Toolbar>
            <div className="postsGrid">
              {author?.posts
                .filter((post) => post.category === "post")
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((post, index) => (
                  <PostCard key={index} post={post} author={false} />
                ))}
            </div>
          </div>
        </Block>
      )}
    </Page>
  );
};

export default AuthorScreen;
