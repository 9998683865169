// @ts-nocheck
import routes from "./routes.jsx";
import React, { useRef, useState, useEffect } from "react";
import "framework7/css/bundle";
import { App, View, Toolbar, Link, f7, Tab, Tabs } from "framework7-react";
import WebApp from "@twa-dev/sdk";

const MyApp = () => {
  WebApp.onEvent("backButtonClicked", () => { f7.views.main.router.back() })
  const appRef = useRef(null);
  const f7Params = {
    name: "Daily Dev",
    routes: routes,
    panel: {
      swipe: true,
    },
    theme: "ios",
    iosTranslucentBars: true,
    iosTranslucentModals: true,
    colors: {
      primary: "#fff",
    },
    bgColor: "#fff",
    dark: true,
    swipeBackPage: true,
    view: {
      iosDynamicNavbar: false,
      stackPages: true,
      iosSwipeBack: true,
      mdSwipeBack: true,
    },
  };

  return (
    <App {...f7Params} ref={appRef}>
      <View main url={"/"} />
    </App>
  );
};

export default MyApp;
