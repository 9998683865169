import WebApp from "@twa-dev/sdk";
import "./join-community-button.css";
const JoinCommunityButton = () => {
  return (
    <div className="row-alignment">
      <span
        onClick={() => WebApp.openTelegramLink("https://t.me/tigestclub")}
        className="theme-opposite-color-medium-text-global join-community-button"
      >
        Join Community
      </span>
    </div>
  );
};

export default JoinCommunityButton;
