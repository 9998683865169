import { f7 } from "framework7-react";
import { formatDate } from "../../utils/format-date";
import "./post-card.css"; // Importing the CSS file
import { postHogHandler } from "../../Posthog/posthog";
import renderVerifiedBadge from "../author-badge/author-badge";
import { useState } from "react";

const PostCard = ({ post, author = true, showBookMark }) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  // console.log("Post", post);

  const handleReadPost = () => {
    console.log("post category", post.category);
    post.category === "resume-review"
      ? f7.views.main.router.navigate("/resume-review")
      : post.category === "job"
      ? window.open(`${post.link}`, "_blank")
      : f7.views.main.router.navigate("/read-post", {
          props: {
            post: post,
          },
        });
    postHogHandler("home-page-post-clicked", {
      ...post,
    });
  };

  const handleBookmark = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(
        "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/bookmark",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            type: post.category,
            post_id: post.id,
          }),
        }
      );

      if (response.ok) {
        const bookmarkResponse = await response.json();
        console.log("Bookmark Response", bookmarkResponse);
        setIsBookmarked(!isBookmarked); // Toggle the bookmark status
      } else {
        console.error("Failed to Bookmark");
      }
    } catch (error) {
      console.error("Bookmark error:", error);
    }
  };

  const handleAuthorClick = () => {
    f7.views.main.router.navigate("/author-screen", {
      props: {
        uuid: post?.authors?.uuid,
      },
    });
    postHogHandler("home-page-author-clicked", {
      uuid: post?.authors?.uuid,
      name: post?.authors?.name,
      link: post?.link,
    });
  };

  return (
    <div
      className="column-alignment post-card-wrapper"
      onClick={handleReadPost}
    >
      <div className="row-alignment">
        {author && (
          <span className="author-info" onClick={handleAuthorClick}>
            {post.category !== "job" && post.authors?.image && (
              <>
                <img src={post.authors.image} className="author-image" />
                <div className="author-name">{post.authors.name}</div>
                {post.authors.is_verified &&
                  renderVerifiedBadge(post.authors.level)}
              </>
            )}
          </span>
        )}
        <span className="fill"></span>
        {/* Bookmark Icon */}
        {showBookMark && (
          <span
            className="bookmark-icon align-self-center"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering handleReadPost
              handleBookmark();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              width="18"
              height="18"
              fill={isBookmarked ? "red" : "white"} // Change color based on bookmark status
            >
              <path
                fill="white"
                d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"
              />
            </svg>
          </span>
        )}
      </div>

      <div className="column-alignment post-content">
        <span className="post-title">
          {post.category === "job" && post.image && (
            <img src={post.image} alt={post.title} className="post-image" />
          )}
          {post.title}
        </span>
        <div className="post-details">
          {post.category !== "job" && post.category !== "resume-review" && (
            <span>
              {post.time_to_read && `${post.time_to_read} m read • `}{" "}
              {formatDate(post.created_at)}
            </span>
          )}
        </div>
        {post.category !== "job" && (post.image || post.resume_url) && (
          <img
            src={post.image || post.resume_url}
            alt={post.title}
            className="main-post-image"
          />
        )}
        {post.category !== "job" && post.tags && (
          <div className="post-tags">
            {post.tags.split(",").map((tag, index) => (
              <div key={index} className="tag-tile">
                #{tag.trim()}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PostCard;
