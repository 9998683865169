export const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);

    // Format the date as "Mon DD"
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
    }).format(date);

    return formattedDate;
  }
};
