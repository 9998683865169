// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom/client";
import Framework7React from "framework7-react";
import Framework7 from "framework7/lite/bundle";
import MyApp from "./App.tsx";
// Supports weights 300-800
import '@fontsource-variable/open-sans';
import "./index.css";
import "./assets/global-styles.css";
import "./assets/framework7-icons.css";
import WebApp from "@twa-dev/sdk";
import posthog from 'posthog-js';




import "framework7/css/bundle";

Framework7.use(Framework7React);
posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'always'
});

WebApp.ready();
window.Telegram.WebApp.isClosingConfirmationEnabled = true;
WebApp.expand();
WebApp.disableVerticalSwipes();
WebApp.headerColor = "#000000";

posthog.identify(
    WebApp.initDataUnsafe.user?.id,  // Replace 'distinct_id' with your user's unique identifier
    { id: WebApp.initDataUnsafe.user?.id, name: `${WebApp.initDataUnsafe.user?.first_name} ${WebApp.initDataUnsafe.user?.last_name}`, username: WebApp.initDataUnsafe.user?.username, props: WebApp.initDataUnsafe.user } // optional: set additional person properties
);


ReactDOM.createRoot(document.getElementById("root")!).render(
    <MyApp />
);
