import React from "react";
import "./card.css";
import { f7 } from "framework7-react";
import { FaCalendarAlt } from "react-icons/fa";

const TopmateCard = ({ title, subtitle, iframeSrc }) => {
  const handleCardClick = () => {
    f7.views.main.router.navigate("/iframe", {
      props: {
        iframeSrc: iframeSrc,
      },
      transition: "f7-circle",
    });
  };

  return (
    <div className="card" onClick={handleCardClick}>
      <div className="card-content">
        <div className="icon">
          <FaCalendarAlt />
        </div>
        <div className="text">
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default TopmateCard;
