import React from "react";
import { f7 } from "framework7-react";
import { FaLinkedin, FaTwitter, FaTelegram } from "react-icons/fa";
import WebApp from "@twa-dev/sdk";
import { postHogHandler } from "../../Posthog/posthog";
import renderVerifiedBadge from "../author-badge/author-badge";
import Tag from "../tags/tags";
import config from "../../utils/ui-config.json";

const AuthorProfile = ({ author, showBookMark }) => {
  const handleAuthorClick = () => {
    f7.views.main.router.navigate("/author-screen", {
      props: {
        uuid: author?.uuid,
      },
    });
    postHogHandler("author-profile-clicked", {
      uuid: author?.uuid,
      name: author?.name,
    });
  };

  const handleBookmark = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(
        "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/bookmark",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            type: "author",
            author_id: author.id,
          }),
        }
      );

      if (response.ok) {
        const bookmarkResponse = await response.json();
        console.log("Bookmark Response", bookmarkResponse);
        // setIsBookmarked(!isBookmarked); // Toggle the bookmark status
      } else {
        console.error("Failed to Bookmark");
      }
    } catch (error) {
      console.error("Bookmark error:", error);
    }
  };

  return (
    <div style={styles.profileContainer}>
      <img
        onClick={handleAuthorClick}
        src={author.image}
        alt={author.name}
        style={styles.profileImage}
      />
      <div style={styles.profileInfo}>
        <div style={styles.profileInfoRow}>
          <h1 onClick={handleAuthorClick} style={styles.profileName}>
            {author.name}
            {author.is_verified && renderVerifiedBadge(author.level)}
          </h1>
          <span className="fill"></span>

          <Tag
            text={author.category}
            backgroundColor={
              config.tags?.[author.category] ?? config.tags.default
            }
          />

          {showBookMark && (
            <span
              className="bookmark-icon align-self-center"
              style={{ margin: "0 6px" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering handleReadPost
                handleBookmark();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                width="18"
                height="18"
              >
                <path
                  fill="white"
                  d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"
                />
              </svg>
            </span>
          )}
        </div>
        <div style={styles.socialLinks}>
          {author.twitter && (
            <a
              href={author.twitter}
              onClick={() => {
                WebApp.openLink(author.twitter);
                postHogHandler(`twitter-link-click`, {
                  name: author.name,
                  uuid: author.uuid,
                  link: author?.twitter,
                });
              }}
              target="_blank"
              rel="noopener noreferrer"
              style={styles.socialLink}
            >
              <FaTwitter />
            </a>
          )}
          {author.linkedin && (
            <a
              href={author.linkedin}
              onClick={() => {
                WebApp.openLink(author.linkedin);
                postHogHandler(`linkedin-link-click`, {
                  name: author.name,
                  uuid: author.uuid,
                  link: author.linkedin,
                });
              }}
              target="_blank"
              rel="noopener noreferrer"
              style={styles.socialLink}
            >
              <FaLinkedin />
            </a>
          )}
          {author.telegram && (
            <a
              href={author.telegram}
              onClick={() => {
                WebApp.openTelegramLink(author.telegram);
                postHogHandler(`telegram-link-click`, {
                  name: author.name,
                  uuid: author.uuid,
                  link: author.telegram,
                });
              }}
              target="_blank"
              rel="noopener noreferrer"
              style={styles.socialLink}
            >
              <FaTelegram />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  profileContainer: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    width: "100%",
    color: "#fff",
    borderTop: "1px solid #a8b3cf52",
  },
  profileImage: {
    borderRadius: "20px",
    height: "60px",
    objectFit: "cover",
    marginRight: "15px",
  },
  profileInfo: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  profileInfoRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    // justifyContent: "space-between",
    alignItems: "center",
  },
  profileName: {
    fontSize: "18px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
  },
  socialLinks: {
    display: "flex",
    gap: "10px",
    marginTop: "5px",
  },
  socialLink: {
    color: "#fff",
    fontSize: "20px",
    transition: "color 0.3s ease",
  },
};

export default AuthorProfile;
