import "./comment-wrapper.css";
import { formatDate } from "../../utils/format-date";

const CommentWrapper = ({ comment }) => {
  console.log("comment", comment);
  return (
    <div className="column-alignment comment-wrapper">
      <div className="row-alignment" style={{ gap: "10px" }}>
        <img
          className="user-profile"
          src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=338&ext=jpg&ga=GA1.1.1413502914.1725926400&semt=ais_hybrid"
          alt="user-profile"
        />
        <div className="column-alignment">
          <div className="row-alignment" style={{ gap: "8px" }}>
            <span className="name">
              {comment.profiles?.first_name} {comment.profiles?.last_name}
            </span>
            <div className="row-alignment" style={{ gap: "2px" }}>
              <i
                className="f7-icons align-self-center"
                style={{ fontSize: "14px", color: "#7147ed" }}
              >
                bolt_circle_fill
              </i>
              <span className="reputation-score align-self-center">10</span>
            </div>
          </div>
          <div className="row-alignment" style={{ gap: "8px" }}>
            <span className="username-time">
              {comment.profiles?.username
                ? `@${comment.profiles.username} • `
                : ""}

              {formatDate(comment.created_at)}
            </span>
          </div>
        </div>
      </div>

      <div className="column-alignment" style={{ marginTop: "12px" }}>
        <span className="comment-text">{comment.comment}</span>
        <div
          className="row-alignment action-icons-wrapper"
          style={{ marginTop: "12px", color: "#a8b3cf" }}
        >
          {/* <i className="f7-icons">arrow_up</i>
          <i className="f7-icons">arrow_down</i> */}
          <i className="f7-icons">bubble_right</i>
          <i className="f7-icons">arrow_turn_up_right</i>
          <i className="f7-icons">ellipsis_vertical</i>
          <span className="fill"></span>
          {/* <span>{comment.upvotes} upvotes</span> */}
        </div>
      </div>
    </div>
  );
};

export default CommentWrapper;
